
const FULL_HOST = "https://api.trongrid.io"
const FULL_SHASTA_HOST = "https://api.shasta.trongrid.io"

const tronWebInstance = {
    loggedIn: false,
    tronWeb: null,
    loaded: false,
    loading: null,
};

function pollTronLink(maxTries, pollInterval) {
    return new Promise((resolve) => {
        let tries = 0;
        const timer = setInterval(() => {
            if (window.tronWeb) {
                // Logged in with TronLink
                tries = 0
                clearInterval(timer);
                // window.tronWeb.setFullNode(FULL_HOST)
                resolve({tronWeb: window.tronWeb, loggedIn: true});
            }
            if (tries >= maxTries) {
                const TronWeb = require('tronweb');
                // No TronLink - Create TronWeb instance for call methods
                const tronApi = new TronWeb({
                    fullHost: FULL_HOST,
                    // privateKey: PRIVATE_KEY,
                });
                tries = 0
                clearInterval(timer);
                resolve({tronWeb: tronApi, loggedIn: false});
            }
            tries++
        }, pollInterval);
    });
}

export async function createTronWeb(chainId, privateKey) {
    const TronWeb = require('tronweb');
    const tronWeb = new TronWeb({
        fullHost: chainId == -1 ? FULL_SHASTA_HOST : FULL_HOST,
        // privateKey: privateKey,
    })
    if (!privateKey) {
        tronWeb.setAddress('TMDs8oTj8mVnakqiVyDKdp2ruWPdFeDgbq')
    } else {
        tronWeb.setPrivateKey(privateKey)
    }
    return tronWeb
}

/*

--n-font-weight-strong: 500;
    --n-avatar-size-override: calc(28px - 8px);
    --n-bezier: cubic-bezier(.4, 0, .2, 1);
    --n-border-radius: 2px;
    --n-border: 1px solid rgba(24, 160, 88, 0.3);
    --n-close-icon-size: 14px;
    --n-close-color-pressed: rgba(24, 160, 88, 0.18);
    --n-close-color-hover: rgba(24, 160, 88, 0.12);
    --n-close-border-radius: 2px;
    --n-close-icon-color: #18a058;
    --n-close-icon-color-hover: #18a058;
    --n-close-icon-color-pressed: #18a058;
    --n-close-icon-color-disabled: #18a058;
    --n-close-margin: 0 0 0 4px;
    --n-close-margin-rtl: 0 4px 0 0;
    --n-close-size: 18px;
    --n-color: rgba(24, 160, 88, 0.1);
    --n-color-checkable: #0000;
    --n-color-checked: #18a058;
    --n-color-checked-hover: #36ad6a;
    --n-color-checked-pressed: #0c7a43;
    --n-color-hover-checkable: rgba(46, 51, 56, .09);
    --n-color-pressed-checkable: rgba(46, 51, 56, .13);
    --n-font-size: 14px;
    --n-height: 28px;
    --n-opacity-disabled: 0.5;
    --n-padding: 0 7px;
    --n-text-color: #18a058;
    --n-text-color-checkable: rgb(51, 54, 57);
    --n-text-color-checked: #FFF;
    --n-text-color-hover-checkable: rgb(51, 54, 57);
    --n-text-color-pressed-checkable: rgb(51, 54, 57);
    width: 100%;
}

 */

export async function initTronWeb() {
    const {tronWeb, loggedIn} = await pollTronLink(200, 100);
    tronWebInstance.tronWeb = tronWeb;
    tronWebInstance.loggedIn = loggedIn;
    tronWebInstance.loaded = true;
    return true
}

export async function getTronWebInstance() {
    if (tronWebInstance.loaded) return tronWebInstance;
    if (!tronWebInstance.loading) {
        tronWebInstance.loading = initTronWeb();
    }
    try {
        await tronWebInstance.loading;
    } catch (error) {
        console.log(error)
    }
    return tronWebInstance;
}

export function connectionWallet(){
    if (!window.tronWeb) {
        throw new Error("no wallet")
    }
    return window.tronWeb.request({method: 'tron_requestAccounts'})
}
